import { COOKIE_AUTH_DETAILS } from '@/config/constants/keys';
import { getCookie, setCookie } from '@/services/cookie';

const initialState = {};
export const subscriptionTier = {
  state: { ...initialState },
  reducers: {
    getSuccess(state, payload) {
      return { ...payload };
    },
    getFailed(state) {
      return { ...state };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getSubscriptionApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response && this.getSuccess(response);
      } catch (error) {
        this.getFailed();
      }
    },
    async upgrade(payload) {
      try {
        const getApi = await import(
          `../../../config/apis/${window.$environment.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('upgradeSubscriptionApi');
        const refreshApiObject = getApi.default('refreshApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: {
              ...apiObject,
              url: `${apiObject.url}?subscriptionTier=${payload?.data?.subscriptionTier}`
            }
          },
          id: payload.apiId
        });

        if (response) {
          const refreshResponse = await dispatch.asyncRequests.handleAsyncRequests(
            {
              payload: {
                data : {refreshToken: getCookie(COOKIE_AUTH_DETAILS)?.refreshToken},
                api: {
                  ...refreshApiObject,
                  url: refreshApiObject.url
                }
              },
              id: payload.apiId
            }
          );
          if (refreshResponse) {
            setCookie(COOKIE_AUTH_DETAILS, refreshResponse);
            payload.callback &&
              payload.callback(
                'success',
                'Subscription updated successfully',
                () => window.location.reload()
              );
          }
        }
      } catch (error) {
        this.getFailed();
        payload.callback('error', error?.message);
      }
    }
  })
};
