import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import React, { lazy, useMemo, useState } from 'react';

import GaugeChart from '@/components/UI/Graph/GaugeChart/Base';
import { pageUrls } from '@/config/constants/keys';
import { hasEnabledFeature, hasRestrictedRole } from '@/utils/utils';
import { Divider, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForward';
import InsightsIcon from '@material-ui/icons/InsertChart';
import { debounce, isEmpty, isObject } from 'lodash';
import moment from 'moment';
import 'rc-tooltip/assets/bootstrap.css';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DonutGraphInfo from './DonutGraphInfo';

const PARKING_MOTION = 'Parked - Motion Sensor';
const PARKING_HIT = 'Parked - Accident';
const PARKING_HEAVY_HIT = 'Parked - Hard Accident';
const DRIVING_HIT = 'Driving Accident';
const DRIVING_HEAVY_HIT = 'Driving Hard Accident';
const HARD_ACCEL = 'Hard Acceleration';
const HARD_BRAKE = 'Hard Brake';
const SHARP_TURN = 'Sharp Turn';
const HARSH_ACCEL = 'Harsh Acceleration';
const HARSH_BRAKE = 'Harsh Brake';
const HARSH_TURN = 'Harsh Turn';
const SEVERE_ACCEL = 'Severe Acceleration';
const SEVERE_BRAKE = 'Severe Brake';
const SEVERE_TURN = 'Severe Turn';
const POTENTIAL_INCIDENT_LOW = 'Accident - Probability Low';
const POTENTIAL_INCIDENT_MEDIUM = 'Accident - Probability Medium';
const POTENTIAL_INCIDENT_HIGH = 'Accident - Probability High';
const ASLEEP = 'Asleep';
const DROWSINESS = 'Drowsiness';
const YAWN = 'Yawn';
const DAYDREAMING = 'Daydream';
const USING_PHONE = 'Phone Use';
const DISTRACTED = 'Distracted';
const SMOKING = 'Smoking';
const NO_SEATBELT = 'No Seat Belt';
const IDLE_NO_DRIVER = 'Vehicle On - Driver Away';
const HEADWAY_MONITORING_WARNING = 'Tailgating - Close';
const HEADWAY_MONITORING_EMERGENCY = 'Tailgating - Dangerous';
const LANE_DEPARTURE_WARNING = 'Lane Departure Warning';
const STOP_SIGN_VIOLATION = 'Stop Sign Violation';
const POWER_LOST = 'No Power';
const MANUAL = 'Manual Event Created';
const FORWARD_COLLISION_WARNING = 'Forward Collision Warning';

const accidentEvents = [PARKING_HIT, PARKING_HEAVY_HIT, DRIVING_HIT, DRIVING_HEAVY_HIT, POTENTIAL_INCIDENT_LOW, POTENTIAL_INCIDENT_MEDIUM, POTENTIAL_INCIDENT_HIGH, FORWARD_COLLISION_WARNING];
const speedingEvents = [PARKING_HEAVY_HIT, HARD_ACCEL, HARD_BRAKE, SHARP_TURN, HARSH_ACCEL, HARSH_BRAKE, HARSH_TURN, SEVERE_ACCEL, SEVERE_BRAKE, SEVERE_TURN, FORWARD_COLLISION_WARNING];
const distractedEvents = [SHARP_TURN, HARD_BRAKE, HARSH_TURN, SEVERE_ACCEL, SEVERE_BRAKE, SEVERE_TURN, ASLEEP, DROWSINESS, YAWN, DAYDREAMING, USING_PHONE, DISTRACTED, SMOKING, NO_SEATBELT, IDLE_NO_DRIVER, LANE_DEPARTURE_WARNING, STOP_SIGN_VIOLATION, POWER_LOST, FORWARD_COLLISION_WARNING];
const START_SECTION = 'Events considered are';

const layout = window?.$environment?.CURRENT_LAYOUT;
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const MainFilter = lazy(() => import(`./MainFilter`));
const InfoCard = lazy(() => import(`./InfoCard`));
const RangeInfo = lazy(() => import(`./RangeInfo`));
const ToolTip = lazy(() => import(`./ToolTip`));
const FleetScore = lazy(() => import(`./FleetScoreInfo`));



const fleetIconMapping = {
  'Tractor Day Cab': 'tractorDayCab',
  'Tractor Sleeper': 'tractorSleeper',
  Bobtail: 'bobTail',
  'Straight Truck': 'straightTruck',
  'Box Truck': 'boxTruck',
  Pickup: 'pickup',
  'Private Passenger': 'privatePassenger',
  Vacuum: 'vacuumTrailer',
  Van: 'van',
  'Other Trailers': 'otherTrailer',
  Flatbed: 'flatBed',
  'Dry Van': 'dryVan',
  Reefer: 'reeferTrailer',
  Tanker: 'tankerTrailer',
  Dump: 'dump',
  Pneumatic: 'pneumaticTrailer',
  'Other Vehicles': 'otherVehicle',
  'Other': 'otherTrailer',
  'infoTruck': 'infoTruck'

};

const Component = ({ Styled, scoreCard, companyId, fetchDetails, ...rest }) => {
  const history = useHistory();
  const [filterValue, setFilterValue] = useState();
  const handleFilterChange = (each) => {
    setFilterValue(each);
    fetchDetails({ data: { id: companyId, ...(each?.value === "YEARLY" ? { isYearly: true } : {}) }, apiId: 'getData' });
  }

  const { t } = useTranslation();

  const scoreCardItemDescription = {
    actualScore: t('scorecard.actualScore'),
    distractedDriving: t('scorecard.distractedDriving')
  }

  const guageScore = useMemo(() => {
    const fleetScore = scoreCard?.fleetScore || 0;
    return fleetScore ? fleetScore < 70
      ? (fleetScore / 2.8) : fleetScore < 80
        ? ((fleetScore - 70) * 2.5) + 25 : fleetScore < 90
          ? ((fleetScore - 80) * 2.5) + 50 : fleetScore < 100
            ? ((fleetScore - 90) * 2.5) + 75 : fleetScore : 0;
  }, [scoreCard?.fleetScore]);

  const titleToolTip = (title = '', info = '', className = 'subHeadTooltip', small = false) => (
    <div>
      <Label {...small ? { className: className } : { variant: "body1", className: `statsHeading ${className}`  }}>
        {title}
        <ToolTip title={info} arrow placement='top'>
          <InfoOutlinedIcon />
        </ToolTip>
      </Label>
    </div>
  );

  const graphContainer = (title = '', data, small = false, info = '', isAccident = false, tooltip = null) => (
    <div className='graph-container'>{small ? tooltip ?  titleToolTip(title, tooltip, 'titleSmall', true) : <Label className="titleSmall">{title}
    </Label> : <SubHeader
      variant="h1"
      className="statsHeading"
      text={tooltip ?  titleToolTip(title, tooltip, 'titleSmall', true) : title}
    />}
      <div className={`fleet-score-graph ${small ? 'smallGraph' : ''}`}>
        <div className='div60'>
          <DonutGraphInfo
            backgroundColor={isAccident ? ['green', 'yellow', 'orange', 'red'] : ['green', 'yellow', 'red']}
            data={data?.chartData}
            centerTextData={{
              count: data?.totalScore,
              text: scoreCardItemDescription?.actualScore
            }}
            small
          />
        </div>
        <div className='div40'><RangeInfo smallGraph={small} isAccident={isAccident} /></div>
      </div>
    </div>
  );

  const lGraphContainer = (title = '', data, small = false, info = '', cardInfo = '') => (
    <div><SubHeader
      variant="h1"
      className="statsHeading"
      text={titleToolTip(title, info)}
    />
      <div className='infoPosition'>
        <div className='card'>
          <span className='text'>{cardInfo}</span>
          <span className='count'>{data?.totalDays}</span>
        </div>
      </div>
      <div className={`fleet-score-graph2`}>
        <div className='div60'> <DonutGraphInfo isUnPoweredViolation centerTextData={{
          count: data?.totalScore,
          text: scoreCardItemDescription?.actualScore
        }} data={data?.chartData} /></div>
        <Styled.DIV40 isBottomAlign><RangeInfo isUnPoweredViolation smallGraph={small} /></Styled.DIV40>
      </div>
    </div>
  );

  const fleetScore = (
    <div className='grid-item'>
      <SubHeader
        variant="h1"
        className="statsHeading"
        text={'Fleet Score'}
      />
      <div className='fleet-score-graph3'>
        <div className='div60'><GaugeChart value={guageScore} textClassName="totalScore" /></div>
        <div className='div40'><RangeInfo fleetScore /></div>
      </div>
      <div style={{
        width: '62%',
        textAlign: 'center',
        marginLeft: "5%",
      }}>
        <Label className="label" variant="h1">{scoreCard?.fleetScore}</Label>
      </div>
      <Divider className='divider-style' />
      <FleetScore scoreCard={scoreCard} />
    </div>
  );

  const handleClick = debounce((event) => {
    if (!hasRestrictedRole(['FLMANAGER', 'ADMIN'])) {
      const endTime = moment().valueOf();
      const startTime = filterValue?.value === "YEARLY"
        ? moment().subtract(1, 'year').valueOf()
        : moment().subtract(30, 'days').valueOf();
      history.push(`${pageUrls?.cameraList}?endTime=${endTime}&startTime=${startTime}`);
    }
  }, 100);

  const driverBehaviour = (
    <div className='grid-item'>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <div>
          <SubHeader
            variant="h1"
            className="statsHeading title"
            text={titleToolTip(t('scorecard.driverBehavior'), (
              <p>
                <Trans i18nKey='scorecard.driverBehaviorDesc' />
              </p>
            ))}
          />
        </div>
        <div>
          {!hasRestrictedRole(['UNDER_WRITER']) && hasEnabledFeature('cameraIntegrationEnabled') && <Grid style={{ display: "flex" }} justify="flex-end">
            <Button variant="text" size="small" onClick={(event) => {
              handleClick(event)
            }} style={{
              background: 'transparent',
            }} endIcon={<ArrowForwardIosIcon />}>{t('scorecard.moreInfo')}</Button>
          </Grid>}
        </div>
      </div>
      <div className='subCharts'>
        <div className="part1">
          <div>
            <div className='fleet-score-graph'>
              <div className='div60'><DonutGraphInfo centerTextData={{
                count: scoreCard?.totalDrivingBehaviorScore,
                text: scoreCardItemDescription?.actualScore
              }} onClick={() => { }} hideHover data={scoreCard?.totalDrivingBehaviorData} backgroundColor={['#eaeaea', '#908e8e', '#0097e6']} /></div>
              <Styled.DIV40 isBottomAlign><RangeInfo isDriver smallGraph={true} /></Styled.DIV40>
            </div>
          </div>
        </div>
        <div className="part2">
          <div className='row2'>
            <div className='col1'>
              {graphContainer(scoreCardItemDescription?.distractedDriving, scoreCard?.distractedDriving, true, ``, false, `${START_SECTION} ${distractedEvents?.join(', ')}`)}
            </div>
          </div>
          <div className='row1'>
            <div className='col1'>
              {graphContainer(t('scorecard.accident'), scoreCard?.accident, true, ``, true,  `${START_SECTION} ${accidentEvents?.join(', ')}`)}
            </div>
            <div className='col2'>
              {graphContainer(t('scorecard.speeding'), scoreCard?.speeding, true, ``, false, `${START_SECTION} ${speedingEvents?.join(', ')}`)}
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );

  const unpoweredDays = (
    <div className='grid-item2 topMargin'>
      <div className="part1">{
        lGraphContainer(
          t('scorecard.unpoweredDays'),
          scoreCard?.unpoweredDays,
          true,
          (
            <p>
              <Trans i18nKey='scorecard.unpoweredDaysGraphDesc' />
            </p>
          ),
          t('scorecard.unpoweredDaysTotalDesc')
        )}
      </div>
      <Divider className='divider-style2' orientation="vertical" />
      <div className="part2">{
        lGraphContainer(
          t('scorecard.radiusViolations'),
          scoreCard?.radiusViolations,
          true,
          (
            <p>
              <Trans i18nKey='scorecard.radiusViolationGraphDesc' />
            </p>
          ),
          t('scorecard.radiusViolationTotalDesc')
        )}
      </div>
    </div>
  );

  if (isEmpty(scoreCard) && isObject(scoreCard)) {
    return (
      <Styled.MainWrapper>
        <Styled.FilterWrapper>
          <div className='filter'><MainFilter onChange={handleFilterChange} /></div>
        </Styled.FilterWrapper>
        <Styled.Wrapper widthOfProgress={90}>
          <div className='noDataFound'>
            <InsightsIcon color="secondary" className='icon' />
            <Label variant="h1" className="text">{t('scorecard.noDataText')}</Label>
          </div>
        </Styled.Wrapper>
      </Styled.MainWrapper>
    );
  }

  if (!scoreCard) {
    return <></>
  }

  return (
    <Styled.MainWrapper>
      <Styled.FilterWrapper>
        <div className='filter'><MainFilter onChange={handleFilterChange} /></div>
        <div className='info'>
          <InfoCard fleetIconMapping={fleetIconMapping} companyName={scoreCard?.companyName} totalCount={scoreCard?.totalVehicleCount} />
        </div>
      </Styled.FilterWrapper>
      <Styled.Wrapper widthOfProgress={90}>
        <div className="left">
          {fleetScore}
        </div>
        <div className="right">
          {driverBehaviour}
          {unpoweredDays}
        </div>
      </Styled.Wrapper>
    </Styled.MainWrapper>
  )
};

export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  scoreCard: PropTypes.object.isRequired
};
