import PropTypes from 'prop-types';
import React, { cloneElement, lazy, useEffect, useState } from 'react';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import {
  COOKIE_AUTH_DETAILS,
  ROLES,
  imageUrls,
  pageUrls
} from '../../../config/constants/keys';
import { deleteCookie, getCookie, setCookie } from '../../../services/cookie';
import {
  getAuthDetails,
  getImagePath,
  getLogo,
  hasRestrictedRole,
  isInsuracePortalUser,
  isdvirOnly
} from '../../../utils/utils';
import Header from '../../Header/Clutch';
import Styled from './privateStyle';
const layout = window?.$environment?.CURRENT_LAYOUT;
const ErrorIcon = lazy(() => import(`../../ErrorIcon/${layout}`));
const SuccessIcon = lazy(() => import(`../../SuccessIcon/${layout}`));
const Button = lazy(() => import(`../../UI/Button/${layout}`));
const Loader = require(`../../Loader`);
const Modal = require(`../../UI/Modal/${layout}`);

const PrivateWrapper = ({
  children,
  layout,
  apiStatus,
  clearRedux,
  setAuth,
  showFilterPopup,
  folderPath,
  handleLogout,
  dashboard,
  fetchCountData,
  fetchDashboardData,
  profile
}) => {
  const isInsurancePortalUserRole = isInsuracePortalUser();
  const isDvirOnlyFlagEnable = isdvirOnly();

  useEffect(() => {
    let apiId = 'fetchCountApi';
    if (layout !== 'welcome' && dashboard?.statsCount?.fleetVehicles === '') {
      if (!isInsurancePortalUserRole && isDvirOnlyFlagEnable) fetchDashboardData({ fetchApi: 'dashboardApi' });
      fetchCountData({
        apiId
      });
    }
  }, []);
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const history = useHistory();
  const i18n = useTranslation();
  const [loaderPopupOpen, setLoaderPopupOpen] = React.useState(false);
  const [openCallback, setOpenCallback] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => (
      <Button
        type="submit"
        label={i18n.t('common.ok')}
        onClick={() => setOpenCallback(false)}
      />
    )
  });
  const isServiceManager = hasRestrictedRole([ROLES.SERVICE_MANAGER]);
  const summaryItems = [
    {
      itemType: "INSHOP",
      url: !isServiceManager ? pageUrls.vehicleNotInUse : null,
      name: i18n.t('dashboard.header.INSHOP'),
      show: true
    },
    {
      itemType: "OUTOFSERVICE",
      url: !isServiceManager ? pageUrls.outOfService : null,
      name: i18n.t('dashboard.header.OUTOFSERVICE'),
      show: true
    },
    {
      itemType: "AVAILABLETRUCKS",
      url: null,
      name: i18n.t('dashboard.header.AVAILABLETRUCKS'),
      show: !isServiceManager
    },
  ]
  useEffect(() => {
    let isPageLoading = false;
    for (let index = 0; index < apiStatus.status.length; index++) {
      const element = apiStatus.status[index];
      if (element.isLoading) {
        isPageLoading = true;
      }
      if (element.errorMessage === 'Access denied') {
        setLoaderPopupOpen(false);

        deleteCookie(COOKIE_AUTH_DETAILS);
        clearRedux();
        setAuth(null);
        window.location = pageUrls.login;
        return;
      }
    }
    isPageLoading ? setLoaderPopupOpen(true) : setLoaderPopupOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus.status]);

  const bigDrawerWidth = '15.1%';
  const bigDrawerWidthNoUnit = '15.1';
  const smDrawerWidth = '6.1%';
  const smDrawerWidthNoUnit = '6.1';

  const sm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const lg = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  const mdWidth = md ? smDrawerWidth : bigDrawerWidth;
  let width = sm ? bigDrawerWidth : mdWidth;
  const mdWidthNoUnit = md ? smDrawerWidthNoUnit : bigDrawerWidthNoUnit;
  let widthNoUnit = sm ? bigDrawerWidthNoUnit : mdWidthNoUnit;

  width = open ? width : '4.3%';
  widthNoUnit = open ? widthNoUnit : '4.3';
  const handleDrawerOpen = () => {
    document.getElementById('logoContainer').style.display = 'none';
    setOpen(true);
    setTimeout(() => {
      document.getElementById('logoContainer').style.display = 'block';
    }, 50);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openLogo = open ? imageUrls.logo : imageUrls.smallLogo;
  const logo = sm || md || lg ? imageUrls.smallLogo : openLogo;

  const image = getImagePath(imageUrls.bg_image);
  const handleOnLogout = () => {
    const auth = getAuthDetails(false);
    if (auth) {
      handleLogout({
        apiId: 'logout',
        callback: (type, message) => {
          if (type === 'error') {
            callback(type, message);
          } else {
            setAuth(null);
            // history.push(pageUrls.login);
            window.location.href = pageUrls.login;
          }
        }
      });
    } else {
      window.location.href = pageUrls.login;
    }
  };
  const [username, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [profileIcon, setProfileIcon] = useState('')
  useEffect(() => {
    const token = getCookie(COOKIE_AUTH_DETAILS);
    if (token) {
      setUserName(`${token.firstName} ${token.lastName}`);
      setCompanyName(token.companyName)
      if (token.firstName) {
        let firstName = token.firstName.replace(/[^a-zA-Z0-9 ]/g, "").trim()
        let lastName = token.lastName.replace(/[^a-zA-Z0-9 ]/g, "").trim()
        if (lastName) {
          if (firstName) setProfileIcon(`${firstName[0]}${lastName[0]}`)
          else setProfileIcon(`${lastName[0]}`)
        } else if (firstName) {
          setProfileIcon(`${firstName[0]}`)
        }
      }
    }
    // else {
    //   handleOnLogout();
    // }
  }, []);
  useEffect(() => {
    if (window.location.href.includes('resetPassword')) {
      if (profile) {
        setUserName(`${profile.firstName} ${profile.lastName}`);
        if (profile.firstName) {
          let firstName = profile.firstName.replace(/[^a-zA-Z0-9 ]/g, "").trim()
          let lastName = profile.lastName.replace(/[^a-zA-Z0-9 ]/g, "").trim()
          if (lastName) {
            if (firstName) setProfileIcon(`${firstName[0]}${lastName[0]}`)
            else setProfileIcon(`${lastName[0]}`)
          } else if (firstName) {
            setProfileIcon(`${firstName[0]}`)
          }
        }
      }
    }
  }, [profile])
  //Callback function to be invoked after an api call
  const callback = (type, message, callbackFunction, errors, optionButton) => {
    if (message) {
      let keys = Object.keys(message);
      let items = Object.values(message);
      if (typeof message !== 'string' && keys.length > 1) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      if (!message?.error) message.error = [];
      setModalListObject({
        header: '',
        content: () => (
          <div className="successpopup">
            {type === 'error' ? <ErrorIcon /> : type === 'warningfmcsa' ?
              <img
                src={getImagePath(imageUrls.confirmIcon)}
                alt="Are you sure"
              /> :
              <SuccessIcon />}
            {typeof message === 'string' ? (
              <><h2 >{i18n.t(message)}</h2>
                {errors && errors.length > 0 && <div >{errors.map(ele => <div >{ele}</div>)}</div>}</>
            ) : keys.length === 1 && items[0].length === 1 ? (
              <h2> {i18n.t(items)} </h2>
            ) : keys.length === 1 && items[0].length !== 1 ? (
              <div className="errorSectionlist">
                {keys.map((item, index) => {
                  return (
                    <div className="listItem">
                      <span>Line No:{item}</span>
                      <ul>
                        {message[item]?.length && message[item].map((err, index) => {
                          return <li>{err}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            ) : type !== 'error' && message?.error?.length === 0 ? (
              <h2> {i18n.t('common.dataImportSuccess')} </h2>
            ) : (
              <div className="errorSectionlist">
                {keys.map((item, index) => {
                  return (
                    <div className="listItem">
                      <span>Line No:{item}</span>
                      <ul>
                        {message[item]?.length && message[item].map((err, index) => {
                          return <li>{err}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            )}
            {
              optionButton ? <div className="deletePopupBtn">
              <div className="cancelBtn">
                <Button
                  label={i18n.t('Cancel')}
                  type="submit"
                  onClick={() => {
                    setOpenCallback(false);
                  }}
                ></Button>
              </div>
              <div className="deletebtn">
                <Button
                  type="submit"
                  label={i18n.t('common.deletePopup')}
                  onClick={() => {
                      callbackFunction()
                    }}
                  ></Button>
                </div>
              </div> 
              : 
              <div className="deletePopupBtn">
                <Button
                  type="submit"
                  label={i18n.t('common.ok')}
                  onClick={() => {
                    setOpenCallback(false);
                    callbackFunction && callbackFunction();
                    if (
                      type !== 'error' &&
                      message?.type === 'Users' &&
                      window.location.href.includes('user')
                    )
                      history.push(pageUrls.userList);
                    else if (
                      type !== 'error' &&
                      (message?.type === 'Vehicles' ||
                        message?.type === 'Trailers') &&
                      window.location.href.includes('vehicle')
                    )
                      history.push(pageUrls.vehicleList);
                    else if (
                      type !== 'error' &&
                      window.location.href.includes('alerts') &&
                      window.location.href.includes('requests')
                    )
                      history.push(pageUrls.alertsRequests);
                    else if (
                      type !== 'error' &&
                      window.location.href.includes('company') &&
                      message === 'Your subscription has been canceled.'
                    )
                      handleOnLogout();
                    else if (
                      (type === 'mailIdChanged' || type !== 'error') &&
                      window.location.href.includes('user') &&
                      window.location.href.includes('profile') &&
                      message === 'Your profile was saved'
                    ) {
                      handleOnLogout();
                    } else if (
                      type !== 'error' &&
                      window.location.href.includes('user') &&
                      window.location.href.includes('resetPassword') &&
                      message === 'Your profile was saved'
                    ) {
                      handleOnLogout();
                    }
                  }}
                />
              </div>
            }
          </div>
        )
      });
      setOpenCallback(true);
    }
  };
  const token = getCookie(COOKIE_AUTH_DETAILS);
  return (
    <Styled.Wrapper>
      {hasRestrictedRole(['OWNER']) && ((token?.onboardCompleted && window.$environment?.SERVICE_PROVIDER === 'clutch') || window.$environment?.SERVICE_PROVIDER !== 'clutch') ? (
        <>
          <Styled.ClutchHeader isServiceManager={isServiceManager}>
            <div className="logoBox"><img
              alt="logo"
              src={getLogo(imageUrls.innerLogo)}
              className="clutchLogo"
              onClick={() => {
                isDvirOnlyFlagEnable && !hasRestrictedRole(['SERVICE_MANAGER']) ?
                  history.push(pageUrls.pendingMechanicInspections) :
                  isDvirOnlyFlagEnable && hasRestrictedRole(['SERVICE_MANAGER']) ?
                    history.push(pageUrls.unassignedInspections) :
                    hasRestrictedRole(['DRIVER', 'MECHANIC']) ?
                      history.push(pageUrls.profile)
                      : history.push(pageUrls.dashboard);
              }}
            ></img></div>
            <div className="right-side-wrapper">
              {(!hasRestrictedRole(['DRIVER', 'MECHANIC']) || hasRestrictedRole(['FLMANAGER'])) && (
                <div className="countWrapper">
                  {isInsurancePortalUserRole &&
                    (
                      <>
                        <span className="countItem">
                          <a
                            className="count"
                            href={pageUrls.fleetCompanies}
                          >
                            {dashboard?.statsCount?.companyCount}
                          </a>
                          <span className="countText">
                            {i18n.t('fleetCompanies')}
                          </span>
                          <img
                            src={getImagePath(imageUrls.fleetCompanies)}
                            alt="logedIcon"
                          />
                        </span>
                        <span className="countItem">
                          <a
                            className="count"
                            href={pageUrls.upcomingRenewalCompanies}
                          >
                            {dashboard?.statsCount?.renewalCount}
                          </a>
                          <span className="countText">
                            {i18n.t('upcomingRenewals')}
                          </span>
                          <img
                            src={getImagePath(imageUrls.upcomingRenewal)}
                            alt="logedIcon"
                          />
                        </span>
                      </>
                    )
                  }
                  {!isServiceManager &&
                    <span className="countItem">
                      <a
                        className="count"
                        href={`${pageUrls.userList}?role=driver`}
                      // onClick={() => history.push(pageUrls.userList)}
                      >
                        {dashboard?.statsCount?.fleetDrivers}
                      </a>
                      <span className="countText">{i18n.t('fleetDrivers')}</span>
                      <img
                        src={getImagePath(imageUrls.fleetDriver)}
                        alt="logedIcon"
                      />
                    </span>
                  }
                  <span className="countItem">
                    <a
                      className={`count ${!isServiceManager ? '' : 'no-link'}`}
                      href={!isServiceManager ? `${pageUrls.vehicleList}` : null}
                    // onClick={() => history.push(pageUrls.vehicleList)}
                    >
                      {dashboard?.statsCount?.fleetVehicles}
                    </a>
                    <span className="countText">{i18n.t('fleetVehicles')}</span>
                    <img
                      src={getImagePath(imageUrls.fleetVehicle)}
                      alt="logedIcon"
                    />
                  </span>
                  {window.$environment?.SERVICE_PROVIDER === 'riskTheory' && <span className="countItem">
                    <a
                      className={`count ${!isServiceManager ? '' : 'no-link'}`}
                      onClick={() => {
                        setCookie('trailerSelected', true)
                      }}
                      href={!isServiceManager ? `${pageUrls.vehicleList}` : null}
                    // onClick={() => history.push(pageUrls.vehicleList)}
                    >
                      {dashboard?.statsCount?.fleetTrailers}
                    </a>
                    <span className="countText">{i18n.t('fleetTrailers')}</span>
                    <img
                      src={getImagePath(imageUrls.fleetVehicle)}
                      alt="logedIcon"
                    />
                  </span>}
                  {isDvirOnlyFlagEnable &&
                    summaryItems.map((item) => {
                      const config = dashboard?.data?.companySummary?.items?.find(
                        (config) => config.itemType === item.itemType
                      );
                      return item.show && (
                        <span className="countItem without-logo">
                          <a
                            className={`count ${(!config || config?.hasLink) && !isServiceManager ? '' : 'no-link'}`}
                            href={item?.url}
                          >
                            {config?.count ? config?.count : 0}
                          </a>
                          <span className="countText">{item?.name}</span>
                        </span>
                      );
                    })}

                </div>
              )}
              <div className="userprofile">
                {isDvirOnlyFlagEnable && (
                  <span className="nameSection" title={companyName}>
                    {companyName}
                  </span>)}
                <span
                  className="nameSection"
                  title={username}
                  onClick={() => history.push(pageUrls.profile)}
                >
                  {' '}
                  {username}
                </span>
                <span onClick={() => history.push(pageUrls.profile)} className="profileIconLogo">{profileIcon}</span>
                <span
                  className="labelLogout"
                  onClick={() => {
                    handleOnLogout();
                  }}
                ></span>
              </div>
            </div>
          </Styled.ClutchHeader>
          <Header
            auth={token}
            showFilterPopup={showFilterPopup}
            history={history}
          />
          {window.$environment?.SERVICE_PROVIDER === 'clutch' && <Styled.FloatContainer
            onClick={() =>
              window.open('http://h48e7h7dlki.clutcheld.com/', '_blank')
            }
          >
            <img src={getImagePath(imageUrls.helpIcon)} alt="Help Icon" />
          </Styled.FloatContainer>}
        </>
      ) : hasRestrictedRole(['OWNER']) &&
        !token?.onboardCompleted ? null : !hasRestrictedRole(['OWNER']) &&
          layout !== 'welcome' ? (
        <>
          <Styled.ClutchHeader isServiceManager={isServiceManager}>
            <div className="logoBox"><img
              alt="logo"
              src={getLogo(imageUrls.innerLogo)}
              className="clutchLogo"
              onClick={() => {
                isDvirOnlyFlagEnable && !hasRestrictedRole(['SERVICE_MANAGER']) ?
                  history.push(pageUrls.pendingMechanicInspections) :
                  isDvirOnlyFlagEnable && hasRestrictedRole(['SERVICE_MANAGER']) ?
                    history.push(pageUrls.unassignedInspections) :
                    hasRestrictedRole(['DRIVER', 'MECHANIC']) ?
                      history.push(pageUrls.profile)
                      : history.push(pageUrls.dashboard);
              }}
            ></img></div>
            <div className="right-side-wrapper">
              {isInsurancePortalUserRole &&
                (
                  <>
                    <span className="countItem">
                      <a
                        className="count"
                        href={pageUrls.fleetCompanies}
                      // onClick={() => history.push(pageUrls.vehicleList)}
                      >
                        {dashboard?.statsCount?.companyCount}
                      </a>
                      <span className="countText">
                        {i18n.t('fleetCompanies')}
                      </span>
                      <img
                        src={getImagePath(imageUrls.fleetCompanies)}
                        alt="logedIcon"
                      />
                    </span>
                    <span className="countItem">
                      <a
                        className="count"
                        href={pageUrls.upcomingRenewalCompanies}
                      >
                        {dashboard?.statsCount?.renewalCount}
                      </a>
                      <span className="countText">
                        {i18n.t('upcomingRenewals')}
                      </span>
                      <img
                        src={getImagePath(imageUrls.fleetCompanies)}
                        alt="logedIcon"
                      />
                    </span>
                  </>
                )
              }
              {(!hasRestrictedRole(['DRIVER', 'MECHANIC']) || hasRestrictedRole(['FLMANAGER'])) && (
                <div className="countWrapper">
                  {!isServiceManager &&
                    <span className="countItem">
                      <a
                        className="count"
                        href={`${pageUrls.userList}?role=driver`}
                      >
                        {dashboard?.statsCount?.fleetDrivers}
                      </a>
                      <span className="countText">{i18n.t('fleetDrivers')}</span>
                      <img
                        src={getImagePath(imageUrls.fleetDriver)}
                        alt="logedIcon"
                      />
                    </span>
                  }
                  <span className="countItem">
                    <a
                      className={`count ${!isServiceManager ? '' : 'no-link'}`}
                      href={!isServiceManager ? `${pageUrls.vehicleList}` : null}
                    // onClick={() => history.push(pageUrls.vehicleList)}
                    >
                      {dashboard?.statsCount?.fleetVehicles}
                    </a>
                    <span className="countText">{i18n.t('fleetVehicles')}</span>
                    <img
                      src={getImagePath(imageUrls.fleetVehicle)}
                      alt="logedIcon"
                    />
                  </span>
                  {window.$environment?.SERVICE_PROVIDER === 'riskTheory' && <span className="countItem">
                    <a
                      className={`count ${!isServiceManager ? '' : 'no-link'}`}
                      onClick={() => {
                        setCookie('trailerSelected', true)
                      }}
                      href={!isServiceManager ? `${pageUrls.vehicleList}` : null}
                    // onClick={() => history.push(pageUrls.vehicleList)}
                    >
                      {dashboard?.statsCount?.fleetTrailers}
                    </a>
                    <span className="countText">{i18n.t('fleetTrailers')}</span>
                    <img
                      src={getImagePath(imageUrls.fleetVehicle)}
                      alt="logedIcon"
                    />
                  </span>}
                  {isDvirOnlyFlagEnable &&
                    summaryItems.map((item) => {
                      const config = dashboard?.data?.companySummary?.items?.find(
                        (config) => config.itemType === item.itemType
                      );
                      return item.show && (
                        <span className="countItem without-logo">
                          <a
                            className={`count ${(!config || config?.hasLink) && !isServiceManager ? '' : 'no-link'}`}
                            href={item?.url}
                          >
                            {config?.count ? config?.count : 0}
                          </a>
                          <span className="countText">{item?.name}</span>
                        </span>
                      );
                    })}
                </div>
              )}
              <div className="userprofile">
                {isDvirOnlyFlagEnable && (<span className="nameSection" title={companyName}>
                  {companyName}
                </span>)}
                <span
                  className="nameSection"
                  title={username}
                  onClick={() => history.push(pageUrls.profile)}
                >
                  {' '}
                  {username}
                </span>
                <span onClick={() => history.push(pageUrls.profile)} className="profileIconLogo">{profileIcon}</span>
                <span
                  className="labelLogout"
                  onClick={() => {
                    handleOnLogout();
                  }}
                ></span>
              </div>
            </div>
          </Styled.ClutchHeader>
          <Header
            auth={token}
            showFilterPopup={showFilterPopup}
            history={history}
          />
          {window.$environment?.SERVICE_PROVIDER === 'clutch' && <Styled.FloatContainer
            onClick={() =>
              window.open('http://h48e7h7dlki.clutcheld.com/', '_blank')
            }
          >
            <img src={getImagePath(imageUrls.helpIcon)} alt="Help Icon" />
          </Styled.FloatContainer>
          }
        </>
      ) : null}
      <Styled.Main style={{ marginBottom: window.$environment?.SERVICE_PROVIDER === 'riskTheory' ? '30px' : '0' }} widthnounit={widthNoUnit} drawerwidth={width} image={image}>
        <Styled.Content>{cloneElement(children, { callback })}</Styled.Content>
      </Styled.Main>
      {window.$environment?.SERVICE_PROVIDER === 'riskTheory' && <div className="footerClass">
        <span>Need help?</span> Call <b>844-465-6535</b> from 7am-7pm, Monday-Friday.
      </div>}
      <Modal.default
        open={loaderPopupOpen}
        setOpen={setLoaderPopupOpen}
        data={{
          header: '',
          content: () => <Loader.default />
        }}
        isLoader
      />
      <Modal.default
        open={openCallback}
        setOpen={setOpenCallback}
        data={ModalListObject}
        isSuccess
        isError={isError}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      />
    </Styled.Wrapper>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    dashboard: state.dashboard,
    profile: state.resetPassword
  };
};
const mapDispatch = (dispatch) => {
  return {
    clearRedux: () => dispatch({ type: 'RESET_APP' }),
    handleLogout: (data) => dispatch.login.handleLogout(data),
    fetchCountData: (data) => dispatch.dashboard.fetchCountData(data),
    fetchDashboardData: (data) => dispatch.dashboard.fetchData(data),
  };
};

export default connect(mapState, mapDispatch)(PrivateWrapper);

PrivateWrapper.defaultProps = {
  children: null,
  auth: null,
  layout: ''
};
PrivateWrapper.propTypes = {
  children: PropTypes.element,
  auth: PropTypes.object,
  layout: PropTypes.string,
  clearRedux: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired
};
