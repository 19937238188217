import axios from 'axios';
import { AUTH_TOKEN, COOKIE_AUTH_DETAILS } from '../config/constants/keys';
import { getCookie, setCookie } from '../services/cookie';
const fileDownload = require('js-file-download');
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'
const beBaseUl = window?.$environment?.BE_BASE_URL;
const instance = axios.create({
  baseURL: beBaseUl
});

let formConfig = null;
let isPublic = false;
export const fetch = async (payload) => {
  formConfig = '';
  isPublic = false;
  let requestPayload = {};
  if (payload.api.isQueryString) {
    const query = Object.keys(payload.data)
      .map((key) => `${key}=${payload.data[key]}`)
      .join('&');
    requestPayload.url = `${payload.api.url}?${query}`;
  } else if (payload.api.isFormData) {
    formConfig = {
      'content-type': 'multipart/form-data'
    };
    let formData = new FormData();
    Object.keys(payload.data).map((key) => {
      formData.append(key, payload.data[key]);
    });
    requestPayload = { data: formData, url: payload.api.url };
  } else {
    requestPayload = payload.data
      ? { ...payload, url: payload.api.url }
      : { url: payload.api.url };
  }
  isPublic = payload.api.isPublic || false;
  switch (payload.api.type) {
    case 'get':
      return await getRequest(requestPayload && { ...requestPayload });
    case 'post':
      return await postRequest(requestPayload && { ...requestPayload });
    case 'delete':
      return await deleteRequest(requestPayload && { ...requestPayload });
    case 'put':
      return await putRequest(requestPayload && { ...requestPayload });
    default:
      return null;
  }
};
const getRequest = async ({ url, data }) => {
  const response = await instance.get(url, data);
  return response;
};

const postRequest = async ({ url, data }) => {
  const response = await instance.post(url, data);
  return response;
};

const deleteRequest = async ({ url, data }) => {
  const response = await instance.delete(url, data);
  return response;
};

const putRequest = async ({ url, data }) => {
  const response = await instance.put(url, data);
  return response;
};

instance.interceptors.request.use(
  async function (config) {
    const needAuthorization = !isPublic;
    if (needAuthorization) {
      const token = getCookie(COOKIE_AUTH_DETAILS)[AUTH_TOKEN];
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      } else {
        const configData = await Promise.reject({ message: 'Access denied' });
        return configData;
      }
    } else {
      delete config.headers['Authorization'];
    }
    config.headers = {
      ...config.headers,
      'Accept-Language': isEs() ? 'es' : 'en'
    }
    if (window.$environment.SERVICE_PROVIDER == 'eldx') {
      config.headers = {
        ...config.headers,
        'reseller-path': window.$environment.RESELLER.slice(1)
      }
    }
    if (formConfig) {
      config.headers = {
        ...config.headers,
        'content-type': 'multipart/form-data'
      };
    }
    const configData = await config;
    return configData;
  },
  async function (error) {
    const configData = await Promise.reject({ message: error });
    return configData;
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  async function (response) {
    let serviceProviderFilename = {
      em: 'ELD Mandate',
      gs: 'Gorilla Safety',
      clutch: 'Clutch',
      tp: 'Trucker Path',
      trackon: 'Trackon',
      spireon: 'Spireon',
      webfleet: 'Webfleet',
      riskTheory: 'Constitution Risk Management',
      at: 'Allways Track',
      eldx: 'ELD X',
      azuga: 'Azuga',
      simplex: 'Simple X',
      trackit: 'Command Alkon',
      ft: 'Fleet Track',
      hcss: "HCSS eLogs",
      patriot: 'Patriot ELD'
    };
    let filename = '';
    let disposition = response.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    let fileName = filename
      ? filename.split('.')[0]
      : serviceProviderFilename[window.$environment.SERVICE_PROVIDER];

    const url = response.config.url.replace(response.config.baseURL, '');
    if (response.headers['content-type'] === 'application/csv;charset=UTF-8') {
      fileDownload(response.data, `${fileName}.csv`);
    } else if (
      response.headers['content-type'] === 'application/pdf;charset=UTF-8'
    ) {
      fileDownload(response.data, `${fileName}.pdf`);
    } 
    if(response.status === 206 && url.includes('login')) {
      return response;
    }
    if(response.status === 206) {
      let message = response.data.errors
      const errorData = await Promise.reject({ message });
      return errorData;
    }
    if(response.status === 207) {
      return response;
    }
    if (response?.data?.errors) {
      const errorData = await Promise.reject({
        message: response?.data?.errors
      });
      return errorData;
    } else {
      if (url === 'recovery') {
        const errorData = await Promise.reject({
          message: 'If your email address is registered with us, you will receive a password recovery link at your email address.'
        });
        return errorData;
      }
      if (url.indexOf('/alerts/chat') === 0) {
        response.data = { data: 'Deleted!' };
      }
      const responseData = await response;
      return responseData;
    }
  },
  async function (error) {
    if (error.message && !error.response) {
      const errorData = await Promise.reject({
        message: 'api.errors.noService'
      });
      return errorData;
    } else if (error.response.status === 401) {
      return error.response;
    } else if (error.response.status === 403 && !error.response.config.url.includes('wiredrhino')) {
      const cookie = getCookie(COOKIE_AUTH_DETAILS);
      if (cookie.refreshToken) {
        isPublic = true;

        const refreshResponse = await instance.post(
          '/refresh' , {refreshToken: cookie.refreshToken}
        );
        if (refreshResponse.status === 401) {
          const errorData = await Promise.reject({
            message: 'Access denied'
          });
          return errorData;
        } else {
          isPublic = false;
          const token = getCookie(COOKIE_AUTH_DETAILS);
          setCookie(
            COOKIE_AUTH_DETAILS,
            refreshResponse.data.data,
            token.rememberMe
          );
          const repeatedResponse = await instance.request(error.config);
          if (repeatedResponse.data.data) {
            return repeatedResponse;
          }
          if (repeatedResponse.data.errors.length) {
            await Promise.reject({
              message: repeatedResponse.data.errors[0]
            });
          }
        }
      } else {
        const errorData = await Promise.reject({ message: 'Access denied' });
        return errorData;
      }
    } else if (error.response.status === 412) {
      let message =
        error.response.data.errors?.errorList || error.response.data.errors;
      const errorData = await Promise.reject({ message });
      return errorData;
    } else if (error.response.status) {
      let message = '';
      if (error.response.status === 400) {
        message =
          error?.response?.data?.errors ||( isEs() ? 'Petición incorrecta' :'Incorrect or corrupted request');
      } else if (error.response.data?.errorMessage) {
        message = error.response.data.errorMessage;
      } else {
        message =
          error.response.data.errors && error.response.data.errors[0]
            ? error.response.data.errors[0]
            : error.message
            ? error.message
            : 'api.errors.networkError';
      }
      const errorData = await Promise.reject({ message, status: error.response.status });
      return errorData;
    }
  }
);
