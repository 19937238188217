import {
  pageUrls,
} from '@/config/constants/keys';
import { UI_DATE_FORMAT, UI_LOGBOOK_DATE } from '@/config/constants/static';
import { getDateLocale, getLastQuaters } from '@/utils/utils';
import { Field } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const RangePicker = lazy(() => import(`@/components/UI/DateRangePicker/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));


const RenderComponent = ({
  values,
  handleEdit,
  validateForm,
  customProps,
  submitAction
}) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsState, setSuggestionsState] = useState([]);
  useEffect(() => {
      setSuggestions([...customProps.activeVehicles, ...customProps?.deActivatedVehicles]);
      setSuggestionsState(customProps?.stateList);
  }, [customProps]);

  useEffect(() => {
    values.id = { label: 'Please Select', value: '' };
  }, [values.vehicleType]);

  return (
    <>
    <div className='button-wrapper'>
      <FormStyle.default.ButtonsWrapper className="vertical-button">
          <Button
            type="submit"
            label={i18n.t('iftaMileage.newState')}
            onClick={(e, d) => {
              history.push(pageUrls?.addState)
            }}
          />
        </FormStyle.default.ButtonsWrapper>
    </div>
    <div className='filter-section'>
      <div className='inputs'>
      <FormStyle.default.FieldsWrapper className="vertical">
          <Label variant="body1" htmlFor="dateRange">
            {`${i18n.t('common.dateFrom')} - ${i18n.t('common.dateTo')}`}
          </Label>
          <FormStyle.default.TextWrapper>
            <Field
              id="dateRange"
              name="dateRange"
              onChange={(date, dateStrings) => {
                handleEdit(dateStrings, { field: 'dateRange' });
                handleEdit(dateStrings[0], { field: 'dateTimeFrom', type: 'date' });
                handleEdit(dateStrings[1], { field: 'dateTimeTo', type: 'date' });

              }}
              predefinedRanges={getLastQuaters()}
              as={RangePicker}
              format={UI_DATE_FORMAT}
              inputReadOnly
              allowClear={false}
              locale={getDateLocale()}
              placeholderText={UI_LOGBOOK_DATE}
              value={values.dateRange.map(ele => moment(ele))}
              showPopperArrow={false}
              showTimeSelect={false}
              maxDate={new Date()}
              showDisabledMonthNavigation
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              isClearable
            />
          </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>
        <FormStyle.default.FieldsWrapper className="vertical">
        <Label variant="body1" htmlFor="dateRange">
            {i18n.t('iftaMileage.vehicle')}
          </Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="vehicle"
                onChange={(event) => {
                  if (!event) {
                    handleEdit({ label: "All", value: '' }, { field: 'id' });
                    return;
                  }
                  handleEdit(event, { field: 'id' });
                }}
                id="vehicle"
                as={Select}
                placeholderText={i18n.t('common.pleaseSelect')}
                value={values.id}
                suggestions={suggestions}
                isClearable={values.id?.value === "" ? false : true}
              />
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
          <FormStyle.default.FieldsWrapper className="vertical">
          <Label variant="body1" htmlFor="dateRange">
            {i18n.t('iftaMileage.stateSearch')}
          </Label>
            <FormStyle.default.TextWrapper>
              <Field
                name="state"
                onChange={(event) => {
                  if (!event) {
                    handleEdit({ label: "All", value: '' }, { field: 'state' });
                    return;
                  }
                  handleEdit(event, { field: 'state' });
                }}
                id="state"
                as={Select}
                placeholderText={i18n.t('common.pleaseSelect')}
                value={values['state']}
                suggestions={suggestionsState}
                isClearable={values.state?.value === "" ? false : true}
              />
            </FormStyle.default.TextWrapper>
          </FormStyle.default.FieldsWrapper>
      </div>
      <div className='buttons'>
      <FormStyle.default.ButtonsWrapper className="vertical-button reportBtn">
          <Button
            label={i18n.t('common.view')}
            onClick={(e, d) => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                submitAction(data);
              });
            }}
          />
        </FormStyle.default.ButtonsWrapper>
        <FormStyle.default.ButtonsWrapper className="vertical-button reportBtn">
          <Button
            label={i18n.t('common.clear')}
            type={'reset'}
            onClick={(e, d) => {
              history.push(pageUrls?.iftaList);
            }}
          />
        </FormStyle.default.ButtonsWrapper>
      </div>
    </div>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  hasApiStatus: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
